import { Component, Input } from '@angular/core';
import { ContactBtnComponent } from '../../../../shared/components/contact-btn/contact-btn.component';

@Component({
  selector: 'app-contact-item',
  standalone: true,
  imports: [ContactBtnComponent],
  templateUrl: './contact-item.component.html',
  styleUrl: './contact-item.component.scss',
})
export class ContactItemComponent {
  @Input({ required: true }) href: string = '';
  @Input({ required: true }) iconSrc: string = '';
}
