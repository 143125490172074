<div class="mySwiper-container">
  <swiper
    [effect]="swiper.effect"
    [grabCursor]="swiper.grabCursor"
    [centeredSlides]="swiper.grabCursor"
    [slidesPerView]="swiper.slidesPerView"
    [coverflowEffect]="swiper.coverflowEffect"
    [pagination]="true"
    [loop]="swiper.loop"
    class="p-0"
  >
    <ng-template swiperSlide *ngFor="let project of projects; let z = index">
      <picture class="w-100">
        <img
          src="../../../../assets/imgs/backgrounds/{{ project.desktop }}"
          alt=""
          class="w-100"
          height="400"
        />
      </picture>
    </ng-template>
  </swiper>
</div>
