import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  private readonly publicKey = 'G6tsupOwXMiN8mTQP';

  sendEmail$(
    serviceID = 'service_effvme2',
    template: string,
    form: FormGroup<any>,
    send_to_mail: string,
    messages: any = {
      tre: 'A breve verrai ricontattato da uno degli psicologi che risponderà alla tua richiesta.',
      cinque: 'Un cordiale saluto,',
      sei: 'Dott. Luca Guglielmi,',
      sette: 'Dott.ssa Giulia Vitale,',
      otto: 'Dott.ssa Zaira Zulian.',
    }
  ): Observable<EmailJSResponseStatus> {
    const { email, message } = form.value;
    const subject = '[STUDIOPIGGLE|WEB] Contact Form';

    const params = {
      send_to_mail,
      subject,
      email,
      message,
      messages,
    };
    return from(emailjs.send(serviceID, template, params, this.publicKey));
  }
}
