<mat-nav-list
  class="h-100 d-flex flex-column justify-content-center align-items-center w-100"
>
  <a
    *ngFor="let nav of pages"
    href="#{{ nav.path }}"
    mat-button
    routerLinkActive="router-link-active"
    (click)="onClose.emit()"
  >
    <h3>
      {{ nav.title | uppercase }}
    </h3>
  </a>
</mat-nav-list>

