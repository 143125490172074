<div class="hero-section">
  <div
    class="d-flex flex-column justify-content-center align-item-center w-100 mt-3"
  >
    <h1 class="home-text-logo primary-title-color mt-3 mb-0">
      {{ "CONFIG.APP_NAME" | translate }}
    </h1>
    <h2 class="home-text-subtitle primary-title-color mt-0 mb-3">
      {{ "FEATURES.HOME.HERO_SECTION.SUBTITLE" | translate }}
    </h2>
  </div>
  <div
    class="d-flex justify-content-center align-items-center flex-column text-center home-text-citazione w-100 mb-3"
  >
    <h4 class="title-one">
      Situato nel cuore di <strong> Torino, </strong> in
      <strong> via Asti 9/a,</strong> vicino a Piazza Gran Madre.
    </h4>
    <h4 class="title-two col-xl-8 col-11">
      Offriamo supporto psicologico per
      <strong> Infanzia, Adolescenza, Genitori </strong>
      <strong> e Giovani Adulti, </strong> uno spazio per scoprire se stessi e
      trovare il proprio benessere.
    </h4>
  </div>
  <div class="d-flex justify-content-center align-items-center w-100 mb-3">
    <a class="btn start-therapy text-white" href="#contacts">
      {{ "FEATURES.HOME.HERO_SECTION.CTA" | translate }}
    </a>
  </div>
  <div
    class="d-flex justify-content-center text-primary align-items-center w-100 mt-3"
  >
    <ng-container *ngIf="!!(isMobile$ | async); else desktopContent">
      <img
        height="400"
        class="w-auto"
        src="../../../../../assets/imgs/backgrounds/home_bg_0.jpg"
        alt="Background Image"
      />
    </ng-container>
    <ng-template #desktopContent>
      <app-carousel class="w-100"></app-carousel>
    </ng-template>
  </div>
</div>
