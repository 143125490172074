import {
  ContactEmail,
  ContactFacebook,
  ContactInfo,
  ContactInstagram,
  ContactLinkedin,
  ContactPhone,
  ContactWhatsApp,
} from '../types/company-contact-info.type';

export const CONTACTS: ContactInfo = {
  email: new ContactEmail(
    'info@studiopigglepsicologia.com',
    'service_effvme2',
    'template_epwcfpr'
  ),
  whatsapp: new ContactWhatsApp('3880776661'),
  instagram: new ContactInstagram('studiopiggle'),
  facebook: new ContactFacebook('studiopiggle'),
  linkedin: new ContactLinkedin('studio-piggle-a8263b305'),
  phoneLink: new ContactPhone('3880776661'),
};
