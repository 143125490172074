import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import * as SharedComponents from './shared/components';
import { MatSidenavModule } from '@angular/material/sidenav';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    SharedComponents.FooterComponent,
    SharedComponents.HeaderComponent,
    SharedComponents.SideNavMenuComponent,
    RouterOutlet,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly pages: {
    path: string;
    title: string;
  }[] = [
    { path: 'home', title: 'Home' },
    { path: 'services', title: 'Servizi' },
    { path: 'about-us', title: 'Chi siamo' },
    { path: 'contacts', title: 'Contatti' },
  ];

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(
    private translate: TranslateService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    // initTranslate
    this.initTranslate();
  }

  private initTranslate() {
    this.translate.addLangs(['it', 'en']);
    this.translate.setDefaultLang('it');
    const browserLang = this.translate.getBrowserLang();
    if (browserLang)
      this.translate.use(browserLang.match(/it|en/) ? browserLang : 'it');
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
