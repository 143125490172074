<section class="services-container">
  <div
    class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 px-xl-5 px-lg-5 px-md-3 px-sm-0 px-0"
  >
    <div class="right-col-container px-2 mx-auto">
      <div class="service-title mb-3">
        <h1>{{ "FEATURES.HOME.SERVICES.TITLE" | translate }}</h1>
      </div>

      <mat-accordion class="custom-accordion">
        <mat-expansion-panel
          class="custom-panel"
          *ngFor="let service of services; let i = index"
          (opened)="panelOpenStates[i] = true"
          (closed)="panelOpenStates[i] = false"
        >
          <mat-expansion-panel-header class="w-100 px-0">
            <mat-panel-title class="custom-panel-title">
              {{ service.title | translate | uppercase }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p
            class="custom-panel-description"
            [innerHTML]="service.description | translate"
          ></p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12 left-col">
    <img src="../../../assets/imgs/backgrounds/services-bg.jpg" alt="" />
  </div>
</section>
