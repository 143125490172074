import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ContactModalFormComponent } from '../contact-modal-form/contact-modal-form.component';
import { Doctor } from '../../types';

@Component({
  selector: 'app-contact-btn-v2',
  standalone: true,
  imports: [
    CommonModule,
    ContactModalFormComponent,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './contact-btn.component.html',
  styleUrl: './contact-btn.component.scss',
})
export class ContactBtnV2Component {
  @Input() icon: string = '';
  @Input() doctor: Doctor | null = null;
  @Input() label: string = '';
  @Input() color:
    | 'primary'
    | 'danger'
    | 'secondary'
    | 'warning'
    | 'success'
    | 'danger' = 'primary';
  @Input() type: 'Phone' | 'Email' | 'Address' | 'social' | '' = '';
  @Input() value: string = '';
  @Input({ required: true }) hoverText: string = '';
  @Input() href: string = '';
  @Input() serviceID: string = '';
  @Input() template: string = '';
  @Input() disableClick: boolean = false;

  constructor(private dialog: MatDialog) {}

  openMailDialog(mail: string): void {
    if (
      this.serviceID &&
      this.type === 'Email' &&
      !this.disableClick &&
      this.doctor
    ) {
      this.dialog.open(ContactModalFormComponent, {
        data: {
          send_to_mail: mail,
          serviceID: this.serviceID,
          template: this.template,
          doctor: this.doctor,
        },
      });
    } else if (this.href) location.href = this.href;
  }
}
