<div class="about-us">
  <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12 px-0">
    <div class="img-container-left">
      <!-- d-none d-sm-block -->
      <img
        class="bg-img"
        src="../../../../../assets/imgs/backgrounds/about-us-bg.jpg"
        alt=""
      />
    </div>
  </div>
  <div
    class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 col-12 right-col px-5"
  >
    <div class="right-col-container mx-auto">
      <div class="title-row mb-2 me-auto text-center w-100">
        <h1>
          {{ "FEATURES.HOME.ABOUT_US.TITLE" | translate }}
        </h1>
      </div>

      <div
        class="d-flex flex-wrap logo-2 w-100 justify-content-center align-items-center"
      >
        @for (quarter of circular_quarters; track quarter) {
        <div
          class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12 col-12"
          [ngClass]="{ 'me-3 me-sm-0': $index === 2 }"
          *ngIf="$index === 2"
        ></div>
        <div
          class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12 col-12 doc-roww"
          [ngClass]="{ 'layout-fix': $index === 0 }"
        >
          <div class="doctor">
            <ng-container *ngIf="doctors[$index] as doctor">
              <div class="doctor-boxx" [routerLink]="['/doctor', doctor.id]">
                @if(doctor && doctor.name){
                <div [id]="quarter" class="curved-corner mb-2">
                  <img [src]="doctor.img" alt="" height="250" width="250" />
                </div>
                <div class="text-center">
                  <h4
                    class="name primary-title-color"
                    [innerHTML]="
                      (doctor.title | translate) +
                      ' ' +
                      (doctor.name | translate)
                    "
                  ></h4>
                  <h6
                    class="job-title"
                    [innerHTML]="doctor.role | translate"
                  ></h6>
                </div>
                }
              </div>
            </ng-container>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
