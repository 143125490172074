import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-side-nav-menu',
  standalone: true,
  imports: [CommonModule, MatListModule],
  templateUrl: './side-nav-menu.component.html',
  styleUrl: './side-nav-menu.component.scss',
})
export class SideNavMenuComponent {
  @Input() pages: {
    path: string;
    title: string;
  }[] = [];
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
}
