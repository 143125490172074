<div class="wrap-btn-clas" (click)="openMailDialog(value)">
  <div class="ciao {{ color }}">
    <div class="btn-slide">
      <span class="circle">
        <mat-icon>{{ icon }}</mat-icon>
      </span>
      <span class="title">
        <span *ngIf="type !== 'Email'">
          {{ value }}
        </span>
        <span *ngIf="type === 'Email'">
          {{ value.split("@")[0] }}
        </span>
        <span *ngIf="type === 'Email'">
          {{ "@" + value.split("@")[1] }}
        </span>
      </span>
      <span class="title-hover">{{ hoverText }}</span>
    </div>
  </div>
</div>
