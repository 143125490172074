import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from '../../../features/contacts/services';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss',
  providers: [
    { provide: ContactService, useClass: ContactService },
    { provide: DoctorsService, useClass: DoctorsService },
  ],
})
export class ContactFormComponent {
  @Input() showTitle: boolean = true;
  @Input() showSendTo: boolean = false;

  form: FormGroup = this.formBuilder.group({
    email: this.formBuilder.control(''),
    message: this.formBuilder.control(''),
  });

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private doctorsService: DoctorsService,
    private _snackBar: MatSnackBar
  ) {}

  onSubmit() {
    if (this.doctorsService.companyDetail) {
      const {
        email: { serviceID, template, value },
      } = this.doctorsService.companyDetail.contacts;

      this.contactService
        .sendEmail$(serviceID, template, this.form, value)
        .subscribe(
          () => this.sendEmailCallback('Email Inviata!'),
          () => this.sendEmailCallback('Email Non Inviata!')
        );
    }
  }

  private sendEmailCallback(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
