import { CompanyDetail } from '../types';
import { ADDRESS } from './address.mock';
import { CONTACTS } from './contacts.mock';
import { DOCTORS } from './doctors.mock';

export const COMPANY_DETAIL: CompanyDetail = {
  name: 'StudioPiggle',
  contacts: CONTACTS,
  address: ADDRESS,
  doctors: DOCTORS,
};
