import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Services } from '../../../../shared/types';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatExpansionModule],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss',
})
export class ServicesComponent {
  @Input() services: Services[] = [];
  panelOpenStates: boolean[] = [false, false, false];
}
