<div class="contact-form w-100 p-3">
  <div class="w-100 d-flex flex-column mb-3" *ngIf="showTitle">
    <h3>Contattaci</h3>
    <h5 class="mb-2">Inizia il tuo percorso con noi..</h5>
  </div>
  <form [formGroup]="form" class="bg-white w-100">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>La tua email</mat-label>

      <input matInput placeholder="La tua email" formControlName="email" />
    </mat-form-field>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Lascia un messaggio</mat-label>

      <textarea
        matInput
        placeholder="Lascia un messaggio e verrai ricontattato."
        formControlName="message"
      ></textarea>
    </mat-form-field>
  </form>
  <div class="row justify-content-around align-items-center w-100">
    <!-- ANNULLA  -->
    <div
      class="mb-3 text-center align-self-center col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12"
    >
      <button
        title="annulla"
        class="w-100"
        mat-stroked-button
        (click)="form.reset()"
      >
        ANNULLA
      </button>
    </div>

    <!-- INVIA  -->
    <div
      class="mb-3 text-center align-self-center col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12"
    >
      <button
        class="w-100"
        color="warn"
        mat-stroked-button
        (click)="onSubmit()"
      >
        INVIA
      </button>
    </div>
  </div>
</div>
