<!-- home -->
<div class="bg-home container">
  <app-hero-section class="w-100" id="home"></app-hero-section>
</div>
<!-- other-container -->
<div class="container-fluid">
  <app-other-services></app-other-services>
</div>
<!-- services -->
<div class="container-fluid">
  <app-services class="w-100" id="services" [services]="services">
  </app-services>
</div>
<!-- quote  -->
<div class="container-fluid">
  <app-quote id="quote" class="w-100"></app-quote>
</div>
<!-- about us -->
<div class="container-fluid">
  <app-about-us
    class="w-100"
    id="about-us"
    [doctors]="companyDetail.doctors"
  ></app-about-us>
</div>
<!-- our-approach -->
<div class="container-fluid">
  <app-our-approach id="our_approach" class="w-100"></app-our-approach>
</div>

<!-- il logo -->
<div class="container-fluid">
  <app-our-logo id="our_logo" class="w-100"></app-our-logo>
</div>

<!-- contacts -->
<div class="container-fluid">
  <app-contacts
    class="w-100"
    id="contacts"
    [companyDetail]="companyDetail"
  ></app-contacts>
</div>
