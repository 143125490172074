<div class="other-container">
  <div class="mb-0">
    <h1 class="heading">
      {{ "FEATURES.HOME.SERVICES_THEME.TITLE" | translate }}
    </h1>
  </div>
  <div class="other-service-container" id="other-service-container">
    <!-- dekstop -->
    <div
      class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 col-12 mb-3 px-3 other-service-row"
      *ngFor="let asd of other_services"
    >
      <div class="img-container mb-0">
        <img [src]="'../assets/imgs/services/' + asd.img" alt="" />
      </div>
      <div>
        <h4>{{ asd.title }}</h4>
      </div>
    </div>

    <!-- mobile -->
    <div
      id="carouselExampleDark"
      class="carousel carousel-dark slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          *ngFor="let asd of other_services; let i = index"
          type="button"
          data-bs-target="#carouselExampleDark"
          [attr.data-bs-slide-to]="i"
          aria-current="true"
          [ngClass]="{ active: i === 0 }"
          [attr.aria-label]="'Slide' + i"
        ></button>
      </div>
      <div class="carousel-inner">
        <div
          class="carousel-item"
          [ngClass]="{ active: i === 0 }"
          [attr.data-bs-interval]="i * 5000"
          *ngFor="let asd of other_services; let i = index"
        >
          <img
            class="d-block"
            [src]="'../assets/imgs/services/' + asd.img"
            height="300"
            alt=""
          />
          <div class="carousel-caption">
            <h4>{{ asd.title }}</h4>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>
