import { CommonModule } from '@angular/common';
import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import SwiperCore, {
  EffectCoverflow,
  Pagination,
  Swiper,
  SwiperOptions,
} from 'swiper';
import { SwiperModule } from 'swiper/angular';
// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);
@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [CommonModule, SwiperModule, RouterModule],
  templateUrl: './carousel.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: './carousel.component.scss',
})
export class CarouselComponent {
  swiper: SwiperOptions = {
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    pagination: true,
    loop: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 3,
      slideShadows: true,
    },
  };

  projects: { desktop: string }[] = [
    { desktop: 'home_bg_0.jpg' },
    { desktop: 'home_bg_1.jpg' },
    { desktop: 'home_bg_2.jpg' },
    { desktop: 'home_bg_3.jpg' },
    { desktop: 'home_bg_4.jpg' },
    { desktop: 'home_bg_5.jpg' },
  ];

  // ngOnInit() {
  //   // if (window.innerWidth <= 768) {
  //   //   this.swiper.slidesPerView = 1;
  //   // }
  // }
}
