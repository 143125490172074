import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LogoComponent } from '../logo/logo.component';
import { HeaderPages } from '../../../core/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButton, MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    LogoComponent,
    TranslateModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  readonly pages: HeaderPages[] = [
    {
      id: 'about-us',
      name: 'CONFIG.PAGES.ABOUT_US',
    },
    {
      id: 'services',
      name: 'CONFIG.PAGES.SERVICES',
    },
    {
      id: 'contacts',
      name: 'CONFIG.PAGES.CONTACTS',
    },
  ];
  @Output() onBtnClick: EventEmitter<string> = new EventEmitter<string>();
}
