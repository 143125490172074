<div class="container">
  <div class="row citazionee px-3 w-100">
    <div class="col-xl-6 col-lg-6 col-md-11 col-12 img-box">
      <img
        class="w-100 my-3"
        src="../../../../../assets/imgs/logo-with-text.webp"
        alt=""
      />
    </div>
    <div class="col-xl-6 col-lg-6 col-md-11 col-12">
      <div class="mb-3">
        <h1 class="custom-section-h1">Il nostro logo</h1>
      </div>
      <h3 class="text-citation mb-3">
        Con il giusto pizzico di fantasia, vuole rappresentare il guscio di un
        uovo, che può schiudersi, trasformarsi e colorarsi delle emozioni e
        della vivacità che ciascuno di noi porta dentro di sé.
      </h3>
      <h3 class="text-citation mb-3">
        In modo creativo il guscio si scompone, differenziandosi in tre parti
        come tre siamo noi, con le nostre similitudini e le nostre differenze.
        Infine, il logo vuole anche rappresentare l’idea di gioco, la parte
        bambina di ciascuno che può trovare spazio e ascolto nelle nostre
        stanze.
      </h3>

      <a
        class="mb-2"
        href="https://www.instagram.com/p/C3-q1HYtY-4/?img_index=2"
      >
        Scopri chi è Piggle
      </a>
    </div>
  </div>
</div>
