import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ContactBtnComponent } from '../../shared/components/contact-btn/contact-btn.component';
import { DoctorsService } from '../../shared/services/doctors/doctors.service';
import { Doctor } from '../../shared/types';
import { ContactBtnV2Component } from '../../shared/components/contact-btn-v2/contact-btn.component';

@Component({
  selector: 'app-doctor',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ContactBtnComponent,
    ContactBtnV2Component,
    TranslateModule,
  ],
  templateUrl: './doctor.component.html',
  styleUrl: './doctor.component.scss',
})
export class DoctorComponent {
  doctor: Doctor | null = null;
  quarter: string = '';

  constructor(
    private route: ActivatedRoute,
    private doctorsService: DoctorsService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      switch (params['id'].trim()) {
        case 'doc-1':
          this.quarter = 'curved-corner-bottomleft';
          break;
        case 'doc-2':
          this.quarter = 'curved-corner-bottomright';
          break;
        case 'doc-3':
          this.quarter = 'curved-corner-topright';
          break;
        default:
          const str = params['id'] || '';
          this.quarter = str ? `${params['id']?.split('-')[1] as number}` : '';
          break;
      }
      this.doctor =
        this.doctorsService.companyDetail.doctors.find(
          (doc) => doc.id === params['id']
        ) || null;
    });
  }
}
