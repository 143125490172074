<footer class="px-4">
  <div class="row mb-3">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-start">
      <img
        class="mb-3"
        src="./../../../assets/imgs/logo-with-text.webp"
        height="40"
        alt=""
      />
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
      <div class="d-flex flex-wrap">
        <div
          class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12"
          *ngFor="let footerNav of navs"
        >
          <div class="title text-purple-dark mb-3">
            {{ footerNav.title }}
          </div>
          <ul class="custom-footer-list">
            <li *ngFor="let section of footerNav.sections" class="m-1">
              <a
                *ngIf="section.href && !section.routerLink"
                [href]="section.href"
              >
                {{ section.name | titlecase }}
              </a>
              <a
                *ngIf="
                  !section.href &&
                  section.routerLink &&
                  footerNav.id === 'doctors'
                "
                [routerLink]="[section.routerLink]"
                [innerHTML]="section.name || '' | translate | titlecase"
              >
              </a>
              <a
                *ngIf="
                  !section.href &&
                  section.routerLink &&
                  footerNav.id !== 'doctors'
                "
                [routerLink]="[section.routerLink]"
              >
                {{ section.name | titlecase }}
              </a>
              <p class="my-auto" *ngIf="!section.routerLink"></p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <mat-divider></mat-divider>
  </div>
  <div class="mb-3 w-100 text-center">
    <a class="text-purple-dark" [href]="'CONFIG.DEV.WEBSITE' | translate">{{
      "CONFIG.DEV.NAME" | translate
    }}</a>
  </div>
</footer>
