<div class="container">
  <div class="citazionee px-3 w-100">
    <h2 class="citazione-desc">
      "{{ "FEATURES.HOME.HERO_SECTION.CITAZIONE.DESCRIPTION" | translate }}"
    </h2>
    <h2>
      {{ "FEATURES.HOME.HERO_SECTION.CITAZIONE.AUTHOR" | translate }}
    </h2>
  </div>
</div>
