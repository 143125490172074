<mat-toolbar class="header-toolbar w-100 bg-home">
  <a class="header-logo" [routerLink]="['/']">
    <img
      height="48"
      width="130"
      src="./../../../assets/imgs/logo-with-text.webp"
      alt=""
    />
  </a>
  <div class="header-nav-menu w-100 d-flex">
    <a
      class="d-none d-md-flex d-lg-flex d-xl-flex"
      *ngFor="let page of pages"
      [href]="'#' + page.id"
      mat-button
      [ngClass]="{
        'contact-btn bg-contact-btn text-white py-2 ms-2':
          page.id === 'contacts'
      }"
    >
      <h3>{{ page.name | translate | uppercase }}</h3>
    </a>
    <button
      class="action-menu-btn d-block d-md-none d-lg-none d-xl-none"
      mat-icon-button
      (click)="onBtnClick.emit('menu')"
      title="menu"
    >
      <mat-icon>menu</mat-icon>
    </button>
  </div>
</mat-toolbar>
