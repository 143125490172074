export class Address {
  mapsUrl: string = 'https://www.google.com/maps/place/';

  get fullAddress(): string {
    return `${this.route}, ${this.house_number}, ${this.cap}, ${this.city}, (${this.province}) - ${this.state}`;
  }

  constructor(
    public route: string,
    public house_number: string,
    public cap: string,
    public city: string,
    public province: string,
    public state: string,
    mapsUrl: string = '',
    public icon: string = 'explore'
  ) {
    if (mapsUrl) {
      this.mapsUrl += mapsUrl;
    }
  }
}
