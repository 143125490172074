import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-other-services',
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIcon],
  templateUrl: './other-services.component.html',
  styleUrl: './other-services.component.scss',
})
export class OtherServicesComponent {
  other_services = [
    {
      img: 'infanzia_no_bg.webp',
      title: 'Infanzia',
    },
    {
      img: 'adolescenza_no_bg.webp',
      title: 'Adolescenza',
    },
    {
      img: 'giovane_adulto_no_bg.webp',
      title: 'Giovani Adulti',
    },
    {
      img: 'genitori_no_bg.webp',
      title: 'Genitori',
    },
  ];
}
