import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { RouterModule } from '@angular/router';
import { ContactBtnComponent } from '../contact-btn/contact-btn.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    ContactBtnComponent,
    MatDividerModule,
    MatIconModule,
    RouterModule,
    TranslateModule,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  providers: [{ provide: DoctorsService, useClass: DoctorsService }],
})
export class FooterComponent {
  navs: {
    id: string;
    title: string;
    sections: {
      name: string;
      type?: 'Phone' | 'Email' | 'Address' | 'social' | '';
      href?: string;
      icon?: string;
      routerLink: string;
    }[];
  }[] = [
    {
      id: 'studio',
      title: 'Studio',
      sections: [
        {
          name: 'Chi siamo',
          routerLink: '',
          href: '#about-us',
        },
        {
          name: 'Servizi',
          routerLink: '',
          href: '#services',
        },
        {
          name: 'Il nostro approccio',
          routerLink: '',
          href: '#our_approach',
        },
      ],
    },
    {
      id: 'doctors',
      title: 'Psicologi',
      sections: [],
    },
    {
      id: 'socials',
      title: 'Contatti',
      sections: [
        {
          name: 'Contatti',
          routerLink: '',
          href: '#contacts',
        },
      ],
    },
  ];

  constructor(private doctorsService: DoctorsService) {
    // Retrieve company detail once to avoid repetitive access
    const companyDetail = this.doctorsService.companyDetail;

    // Iterate through navs array only once
    this.navs.forEach((item) => {
      switch (item.id) {
        case 'doctors':
          item.sections = companyDetail.doctors.map((doc) => ({
            name: doc.name,
            href: '',
            routerLink: `/doctor/${doc.id}`,
          }));
          break;
        case 'socials':
          item.sections = [
            {
              name: '@StudioPiggle',
              icon: companyDetail.contacts.instagram.icon,
              href: companyDetail.contacts.instagram.href,
              type: 'Address',
              routerLink: '',
            },
            {
              name: companyDetail.contacts.phoneLink.toString,
              href: companyDetail.contacts.phoneLink.href,
              icon: companyDetail.contacts.phoneLink.icon,
              type: 'Phone',
              routerLink: '',
            },
            {
              name: companyDetail.contacts.email.value,
              icon: 'icon',
              type: 'Email',
              routerLink: '',
            },
          ];
          break;
        default:
          // Handle other cases if needed
          break;
      }
    });
  }
}
