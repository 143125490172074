<div [class.example-is-mobile]="mobileQuery.matches" class="w-100">
  <!-- header -->
  <app-header class="header w-100" (onBtnClick)="snav.toggle()"></app-header>

  <!-- body -->
  <mat-sidenav-container
    class="custom-mat-sidenav-container my-0 bg-home w-100"
  >
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="true"
    >
      <app-side-nav-menu
        [pages]="pages"
        class="w-100"
        (onClose)="snav.close()"
      ></app-side-nav-menu>
    </mat-sidenav>

    <mat-sidenav-content class="w-100">
      <main class="main-container">
        <router-outlet></router-outlet>
      </main>
      <!-- footer -->
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
