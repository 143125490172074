import { Doctor } from '../types';
import { ContactInfo } from '../types/company-contact-info.type';

export const DOCTORS: Doctor[] = [
  {
    name: 'FEATURES.DOCTOR.DOC_1.NAME',
    role: 'FEATURES.DOCTOR.DOC_1.ROLE',
    title: 'FEATURES.DOCTOR.DOC_1.TITLE',
    description: {
      pt_1: 'FEATURES.DOCTOR.DOC_1.DESCRIPTION.PT_1',
      pt_2: 'FEATURES.DOCTOR.DOC_1.DESCRIPTION.PT_2',
    },
    img: './../assets/imgs/doctors/doc-3.webp',
    state: 'collapsed',
    id: 'doc-1',

    contacts: new ContactInfo(
      '3392520553',
      'zairazulian@studiopigglepsicologia.com',
      'service_3xd5w7d',
      'template_epwcfpr'
    ),
  },
  {
    name: 'FEATURES.DOCTOR.DOC_2.NAME',
    role: 'FEATURES.DOCTOR.DOC_2.ROLE',
    title: 'FEATURES.DOCTOR.DOC_2.TITLE',
    description: {
      pt_1: 'FEATURES.DOCTOR.DOC_2.DESCRIPTION.PT_1',
      pt_2: 'FEATURES.DOCTOR.DOC_2.DESCRIPTION.PT_2',
    },
    img: './../assets/imgs/doctors/doc-2.webp',
    state: 'collapsed',
    id: 'doc-2',
    contacts: new ContactInfo(
      '3293831962',
      'lucaguglielmi@studiopigglepsicologia.com',
      'service_wru4p01',
      'template_epwcfpr'
    ),
  },
  {
    name: 'FEATURES.DOCTOR.DOC_3.NAME',
    role: 'FEATURES.DOCTOR.DOC_3.ROLE',
    title: 'FEATURES.DOCTOR.DOC_3.TITLE',
    description: {
      pt_1: 'FEATURES.DOCTOR.DOC_3.DESCRIPTION.PT_1',
      pt_2: 'FEATURES.DOCTOR.DOC_3.DESCRIPTION.PT_2',
    },
    img: './../assets/imgs/doctors/doc-1.webp',
    state: 'collapsed',
    id: 'doc-3',
    contacts: new ContactInfo(
      '3493058942',
      'giuliavitale@studiopigglepsicologia.com',
      'service_2x41kqs',
      'template_epwcfpr'
    ),
  },
];
