import { Component } from '@angular/core';
import { ContactBtnComponent } from '../../../../shared/components/contact-btn/contact-btn.component';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselComponent } from '../../../../shared/components/carousel/carousel.component';
import {
  BreakpointObserver,
  Breakpoints,
  LayoutModule,
} from '@angular/cdk/layout';
import { Observable, map } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-hero-section',
  standalone: true,
  imports: [
    CommonModule,
    ContactBtnComponent,
    CarouselComponent,
    TranslateModule,
    LayoutModule,
  ],
  templateUrl: './hero-section.component.html',
  styleUrl: './hero-section.component.scss',
})
export class HeroSectionComponent {
  isMobile$: Observable<boolean> = this.breakpointObserver
    .observe([Breakpoints.Handset])
    .pipe(map((result) => result.matches));

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {}
}
