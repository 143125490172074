<div class="container">
  <div class="citazionee px-3 w-100">
    <div class="mb-2">
      <h1 class="custom-section-h1">
        {{ "FEATURES.HOME.CITATION.SUBTITLE" | translate }}
      </h1>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-11 col-12">
      <h3 class="text-citation">
        <strong> L' approccio psicoanalitico </strong>
        è una forma di terapia che
        <strong>
          si concentra sulle radici profonde dei problemi emotivi di una
          persona,</strong
        >
        anziché solo sui comportamenti.
      </h3>
      <h3 class="text-citation">
        Invece di cercare di “correggere” direttamente il modo in cui qualcuno
        si comporta, questo tipo di terapia cerca di capire cosa c'è dietro quei
        comportamenti.
      </h3>
      <h3 class="text-citation">
        <strong>
          Esplora esperienze passate e presenti, conflitti interiori e desideri
        </strong>
        che potrebbero essere sepolti nella mente di una persona.
        <strong> Attraverso la parola e la relazione terapeutica </strong> si è
        aiutati a comprendere meglio se stessi e i propri vissuti.
      </h3>
    </div>
  </div>
</div>
