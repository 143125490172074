import { Injectable } from '@angular/core';
import { CompanyDetail, Services } from '../../types';
import { COMPANY_DETAIL, COMPANY_SERVICES } from '../../mock';

@Injectable({
  providedIn: 'root',
})
export class DoctorsService {
  readonly companyDetail: CompanyDetail = COMPANY_DETAIL;
  readonly services: Services[] = COMPANY_SERVICES;
}
