import { Services } from '../types';

export const COMPANY_SERVICES: Services[] = [
  {
    id: 0,
    title: 'FEATURES.SERVICES.PSICODIAGNOSI.TITLE',
    description: `FEATURES.SERVICES.PSICODIAGNOSI.DESCRIPTION`,
  },
  {
    id: 1,
    title: 'FEATURES.SERVICES.SOSTEGNO.TITLE',
    description: `FEATURES.SERVICES.SOSTEGNO.DESCRIPTION`,
  },
  {
    id: 2,
    title: 'FEATURES.SERVICES.EVOLUTIVA.TITLE',
    description: `FEATURES.SERVICES.EVOLUTIVA.DESCRIPTION`,
  },
  {
    id: 3,
    title: 'FEATURES.SERVICES.GENITORI.TITLE',
    description: `FEATURES.SERVICES.GENITORI.DESCRIPTION`,
  },
];
