import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from '../../../features/contacts/services';
import { DoctorsService } from '../../services/doctors/doctors.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { Doctor } from '../../types';

@Component({
  selector: 'app-contact-modal-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
  ],
  templateUrl: './contact-modal-form.component.html',
  styleUrl: './contact-modal-form.component.scss',
  providers: [
    { provide: ContactService, useClass: ContactService },
    { provide: DoctorsService, useClass: DoctorsService },
  ],
})
export class ContactModalFormComponent {
  showTitle: boolean = true;

  form: FormGroup = this.formBuilder.group({
    email: this.formBuilder.control(''),
    message: this.formBuilder.control(''),
  });

  constructor(
    private dialogRef: MatDialogRef<ContactModalFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      send_to_mail: string;
      serviceID: string;
      template: string;
      doctor: Doctor | null;
    },
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private _snackBar: MatSnackBar
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.data.send_to_mail) {
      const name =
        this.data.send_to_mail === 'lucaguglielmi@studiopigglepsicologia.com'
          ? 'Dott. Luca Guglielmi'
          : this.data.send_to_mail === 'zairazulian@studiopigglepsicologia.com'
          ? 'Dott.ssa Zaira Zulian'
          : this.data.send_to_mail === 'giuliavitale@studiopigglepsicologia.com'
          ? 'Dott.ssa Giulia Vitale'
          : '';
      const messages = !!name
        ? {
            tre: 'A breve verrai ricontattato.',
            cinque: 'Un cordiale saluto,',
            sei: `${name}.`,
            sette: '',
            otto: '',
          }
        : {
            tre: 'A breve verrai ricontattato da uno degli psicologi che risponderà alla tua richiesta.',
            cinque: 'Un cordiale saluto,',
            sei: 'Dott. Luca Guglielmi,',
            sette: 'Dott.ssa Giulia Vitale,',
            otto: 'Dott.ssa Zaira Zulian.',
          };
      this.contactService
        .sendEmail$(
          this.data.serviceID,
          this.data.template,
          this.form,
          this.data.send_to_mail,
          messages
        )
        .subscribe(
          () => this.sendEmailCallback('Email Inviata!'),
          () => this.sendEmailCallback('Email Non Inviata!')
        );
    }
  }

  private sendEmailCallback(message: string) {
    this.dialogRef.close();
    this._snackBar.open(message, '', {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
}
