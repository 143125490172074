import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import * as Features from '../../features';
import * as Components from './components';

import { DoctorsService } from '../../shared/services/doctors/doctors.service';
import { CompanyDetail, Services } from '../../shared/types';
import { HeroSectionComponent } from './components/hero-section/hero-section.component';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    HeroSectionComponent,
    Components.AboutUsComponent,
    Components.QuoteComponent,
    Components.OurApproachComponent,
    Features.ContactsComponent,
    Components.OtherServicesComponent,
    Components.ServicesComponent,
    Components.OurLogoComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HomeComponent {
  companyDetail: CompanyDetail;
  services: Services[] = [];

  constructor(private doctorsService: DoctorsService) {
    this.companyDetail = this.doctorsService.companyDetail;
    this.services = this.doctorsService.services;
  }

  ngOnInit() {
    this.scrollOnTop();
  }

  private scrollOnTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
