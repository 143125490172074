import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ContactBtnComponent } from '../../shared/components/contact-btn/contact-btn.component';
import { ContactFormComponent } from '../../shared/components/contact-form/contact-form.component';
import { CompanyDetail } from '../../shared/types';
import { ContactItemComponent } from './components/contact-item/contact-item.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [
    CommonModule,
    ContactItemComponent,
    ContactBtnComponent,
    ContactFormComponent,
    MatExpansionModule,
    TranslateModule,
    MatIconModule,
  ],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.scss',
})
export class ContactsComponent {
  readonly iconsBasePath: string = '../../../assets/imgs/icons/';
  panelOpenState = false;

  @Input() companyDetail!: CompanyDetail;
}
