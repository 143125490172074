@switch (type) { @case ('Phone') {
<a [href]="href" class="d-flex w-100 justify-content-center align-items-center">
  <button mat-fab color="primary" class="me-3" *ngIf="icon" title="telefono">
    <mat-icon>{{ icon }}</mat-icon>
  </button>
  <div>
    <div *ngIf="label">{{ label }}</div>
    <div class="text-dark">
      {{ value }}
    </div>
  </div>
</a>
} @case ('Email') {
<div mat-button class="contact-btn" (click)="openMailDialog(value)">
  <button mat-fab color="warn" class="me-3" *ngIf="icon" title="email">
    <mat-icon>{{ icon }}</mat-icon>
  </button>
  <div>
    <div *ngIf="label">
      {{ label }}
    </div>
    <div class="text-dark">
      {{ value }}
    </div>
  </div>
</div>
} @case ('Address') {
<a [href]="href" class="d-flex w-100 justify-content-center align-items-center">
  <button mat-fab color="warn" class="me-3" *ngIf="icon" title="indirizzo">
    <mat-icon>{{ icon }}</mat-icon>
  </button>
  <div class="text-dark">
    <div *ngIf="label">
      {{ label }}
    </div>
    <div>
      {{ value }}
    </div>
  </div>
</a>
} @case ('social') {
<a [href]="href" target="_blank" mat-fab color="secondary" class="me-3">
  <img src="../../../../assets/{{ icon }}" alt="" height="24" />
</a>
} }
