import { Address } from '../types';

export const ADDRESS: Address = new Address(
  'Via Asti',
  '9a',
  '10131',
  'Torino',
  'TO',
  'Italia',
  'Via+Asti,+9a,+10131+Torino+TO/@45.0616782,7.703485,17z/data=!4m6!3m5!1s0x4788729913fe015b:0x68ceff0a7a965ff9!8m2!3d45.0616782!4d7.703485!16s%2Fg%2F11hbsvvx21'
);

